import Link from 'next/link';
import React, { FC } from 'react';
import { A } from '@bridebook/ui';
import { AnchorProps } from '@bridebook/ui/src/components/bbcommon/tags/a/a';

interface IProps extends AnchorProps {
  href?: string;
  passHref?: boolean;
}

/**
 * This Link component can be used inside Trans with translations
 * (without this component <Link> with <A> throws an error inside Trans)
 */
export const LinkText: FC<IProps> = ({ href, passHref, children, ...restProps }) => (
  <Link href={href || ''} passHref={passHref} legacyBehavior>
    <A {...restProps}>{children}</A>
  </Link>
);
