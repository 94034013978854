// Size of the tooltip arrow
export const TOOLTIP_ARROW_SIZE = 10;

// Setting for *-right and *-left tooltips where the tip is not centered
export const TOOLTIP_X_OFFSET = 30;

// Setting for *-top and *-bottom tooltips where the tip is not centered
export const TOOLTIP_Y_OFFSET = 10;

// Minimum hover time in ms required to trigger callback event, to avoid triggering it too often
export const TOOLTIP_CALLBACK_DELAY = 500;
